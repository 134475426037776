/* global styles & reset */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: inherit;
  font-weight: lighter;
}

input {
  font-family: 'Encode Sans Expanded', sans-serif !important;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  box-sizing: border-box;
  font-family: 'Encode Sans Expanded', sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.cloudinary-thumbnails {
  display: none !important;
}

* {
  box-sizing: border-box;
}

.datetime-picker {
  position: relative;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-radius: 4px;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  box-sizing: content-box;
  z-index: 100;
}

.datetime-picker .calendar .calendar-nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px;
}

.datetime-picker .calendar .calendar-nav button {
  background: 0 0;
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
}

.datetime-picker .calendar .calendar-nav button .fa {
  font-size: 18px;
}

.datetime-picker .calendar .calendar-nav button:hover {
  background-color: #f0f0f0;
}

.datetime-picker .calendar .calendar-nav .current-date {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 8px;
  cursor: pointer;
}

.datetime-picker .calendar .calendar-nav .current-date:hover {
  background-color: #f0f0f0;
}

.datetime-picker .calendar .calendar-nav .current-date.disabled {
  cursor: default;
}

.datetime-picker .calendar table {
  display: block;
  margin: 4px;
}

.datetime-picker .calendar table td,
.datetime-picker .calendar table th {
  padding: 0;
}

.datetime-picker .calendar table thead {
  display: block;
  margin: 8px 0 3px;
}

.datetime-picker .calendar table thead tr {
  display: flex;
  flex-wrap: wrap;
}

.datetime-picker .calendar table thead th {
  color: #ff9a19;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 400;
}

.datetime-picker .calendar table tbody {
  display: block;
}

.datetime-picker .calendar table tbody tr {
  display: flex;
  flex-wrap: wrap;
}

.datetime-picker .calendar table tbody tr td {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
}

.datetime-picker .calendar table tbody tr td:hover {
  background-color: #f0f0f0;
}

.datetime-picker .calendar table tbody tr td.disabled,
.datetime-picker .calendar table tbody tr td.next,
.datetime-picker .calendar table tbody tr td.prev {
  color: #dedede;
}

.datetime-picker .calendar table tbody tr td.disabled:hover,
.datetime-picker .calendar table tbody tr td.next:hover,
.datetime-picker .calendar table tbody tr td.prev:hover {
  color: #c5c5c5;
}

.datetime-picker .calendar table tbody tr td.disabled:hover {
  color: #dedede;
  background-color: transparent;
  cursor: not-allowed;
}

.datetime-picker .calendar table tbody tr td.now {
  color: #5cc4ef;
  font-weight: 400;
}

.datetime-picker .calendar table tbody tr td.selected {
  background-color: #5cc4ef;
  color: #fff;
  font-weight: 400;
}

.datetime-picker .calendar table tbody tr td.selected:hover {
  background-color: #53c1ee;
  color: #fff;
}

.datetime-picker .calendar table tbody tr td.selected.start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.datetime-picker .calendar table tbody tr td.selected.end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.datetime-picker .calendar table tbody tr td.selected.start.end {
  border-radius: 4px;
}

.datetime-picker .calendar table tbody tr td.between {
  background-color: #f5fbfe;
  border-radius: 0;
}

.datetime-picker .calendar .calendar-days table tbody tr td {
  width: 14.28571429%;
}

.datetime-picker .calendar .calendar-months table tbody tr td {
  width: 33.33333333%;
  height: 40px;
}

.datetime-picker .calendar .calendar-years table tbody tr td {
  width: 25%;
  height: 60px;
}

.datetime-picker .time {
  border-top: 1px solid #efefef;
  padding: 4px;
  display: flex;
  align-items: center;
  position: relative;
}

.datetime-picker .time .show-time {
  display: flex;
  align-items: center;
  flex: 1 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px;
}

.datetime-picker .time .show-time .text {
  line-height: 1;
  font-size: 19px;
  font-family: 'Century Gothic', CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1;
}

.datetime-picker .time .show-time .separater {
  margin: 0 2px 3px;
  line-height: 1;
}

.datetime-picker .time .sliders {
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px;
}

.datetime-picker .time .sliders .slider-text {
  display: none;
}

.datetime-picker .time .sliders .slider {
  display: flex;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background-image: linear-gradient(to right, #dedede, #dedede);
  background-position: 0 50%;
  background-size: 100% 1px;
  background-repeat: no-repeat;
}

.datetime-picker .time .sliders .slider .handle {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  cursor: pointer;
}

.datetime-picker .time .sliders .slider .handle:hover {
  border-color: #b8b8b8;
}

.datetime-picker .time .sliders .slider .handle:active,
.datetime-picker .time .sliders .slider .handle:focus {
  background-color: #5cc4ef;
  border-color: #5cc4ef;
}

.datetime-picker .shortcuts-bar {
  border-bottom: 1px solid #efefef;
  padding: 8px;
}

.datetime-picker .shortcuts-bar .btn {
  border: 0;
  background: 0 0;
  cursor: pointer;
  border-radius: 2px;
  padding: 2px 4px;
  outline: 0;
}

.datetime-picker .shortcuts-bar .btn:hover {
  background-color: #f0f0f0;
}

.datetime-picker .shortcuts-bar .btn:last-child {
  float: right;
}

.datetime-picker .shortcuts-bar .btn:not(:first-child) {
  margin-left: 5px;
}

.datetime-range-picker {
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: content-box;
  z-index: 100;
}

.datetime-trigger {
  position: relative;
}

.datetime-trigger .datetime-picker {
  position: absolute;
  top: 100%;
}

.datetime-range-trigger {
  position: relative;
}

.datetime-range-trigger .datetime-range-picker {
  position: absolute;
  top: 100%;
}

.datetime-picker-popup,
.datetime-range-picker-popup {
  margin-top: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.datetime-picker-popup:before,
.datetime-range-picker-popup:before {
  content: '';
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1;
  left: 10px;
  top: -6px;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
}

/* cloudinary button */

.cloudinary-button {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

/* todo  */
.multiselecttext > span {
  font-size: 1.4rem !important;
}

.multiselectcheckbox > span > svg {
  height: 1.5em !important;
  width: 1.5em !important;
}

/* highlighted text */
.highlighted {
  color: #ff5e00;
  background-color: transparent !important;
}

.no-toolbar{
  border-bottom: none;
}

